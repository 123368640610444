import React, { useState } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Row, Col, Form } from "react-bootstrap"
import elephant from "../../images/topic-3/section-4/elephant-2.jpg"
import ButtonLink from "../../components/buttonLink"
import BottomNav from "../../components/bottomNav"
import Instructions from "../../components/instructions"
import TopHeader from "../../components/topHeader"
import MyBreadcrumb from "../../components/myBreadcrumb"
import MyModal from "../../components/mathModal"
import RespMsg from "../../components/respMsg"
import RadioQuestion from "../../components/radioQuestion"
import parse from "html-react-parser"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"
// =========================================================================================================
const EPopGrowthSection4 = ({ location }) => {
  const { t } = useTranslation()

  const pageContent = {
    breadcrumb: [
      parse(t("Topic3_Section4_breadcrumb[1][0]")),
      parse(t("Topic3_Section4_breadcrumb[1][1]")),
      parse(t("Topic3_Section4_breadcrumb[1][2]")),
      parse(t("Topic3_Section4_breadcrumb[1][3]")),
    ],
    title: [
      parse(t("Topic3_Section4_title[0]")),
      parse(t("Topic3_Section4_title[1]")),
      parse(t("Topic3_Section4_title[2]")),
      parse(t("Topic3_Section4_title[3]")),
    ],
    topText: [
      parse(t("Topic3_Section4_topText[0]")),
      parse(t("Topic3_Section4_topText[1]")),
      "top3",
      "top4",
      parse(t("Topic3_Section4_topText[4]")),
    ],
    labels: [
      [
        parse(t("Topic3_Section4_labels[0][0]")),
        parse(t("Topic3_Section4_labels[0][1]")),
      ],
      [parse(t("Topic3_Section4_labels[1]"))],
      [parse(t("Topic3_Section4_labels[2]"))],
      ["", ""],
    ],

    imageText: [parse(t("Topic3_Section4_imgCaption")), "", "", ""],
    bullets: [
      parse(t("Topic3_Section4_bullets[0]")),
      parse(t("Topic3_Section4_bullets[1]")),
    ],
    bottomText: parse(t("Topic3_Section4_bottomText")),
    instructions: [
      <span>
        {parse(t("Topic3_Section4_instructions[0]"))}
        <span className="linkMeNoColor" onClick={() => setModalShow(true)}>
          {parse(t("Topic3_Section3_instructions.here"))}
        </span>{" "}
        {parse(t("Topic3_Section3_instructions.ifyouneedhelp"))}
      </span>,
      parse(t("Topic3_Section4_instructions[1]")),
      parse(t("Topic3_Section4_instructions[2]")),
      parse(t("Topic3_Section4_instructions[3]")),
    ],
    answers: [
      [1.0, 2],
      [0.92, 85],
      [0.84, 151],
      [0.71, 230],
      [0.58, 275],
      [0.5, 282],
      [0.36, 259],
      [0, 0],
    ],
    radios: [
      {
        label: parse(t("Topic3_Section4_radios[0].label")),
        answer: false,
        msg: parse(t("Topic3_Section4_radios[0].msg")),
      },
      {
        label: parse(t("Topic3_Section4_radios[1].label")),
        answer: true,
        msg: parse(t("Topic3_Section4_radios[1].msg")),
      },
      {
        label: parse(t("Topic3_Section4_radios[2].label")),
        answer: false,
        msg: parse(t("Topic3_Section4_radios[2].msg")),
      },
    ],
    radios2: [
      {
        label: parse(t("Topic3_Section4_radios2[0].label")),
        answer: false,
        msg: parse(t("Topic3_Section4_radios2[0].msg")),
      },
      {
        label: parse(t("Topic3_Section4_radios2[1].label")),
        answer: false,
        msg: parse(t("Topic3_Section4_radios2[1].msg")),
      },
      {
        label: parse(t("Topic3_Section4_radios2[2].label")),
        answer: true,
        msg: parse(t("Topic3_Section4_radios2[2].msg")),
      },
    ],
    msgs: {
      errorMsg: [
        <span>
          {parse(t("Topic3_Section4_msgs.errorMsg[0]"))}
          <span className="linkMeNoColor" onClick={() => setModalShow(true)}>
            {parse(t("Topic3_Section3_instructions.here"))}
          </span>{" "}
          {parse(t("Topic3_Section3_instructions.ifyouneedhelp"))}
        </span>,
        <span>
          {parse(t("Topic3_Section4_msgs.errorMsg[1]"))}
          <span className="linkMeNoColor" onClick={() => setModalShow(true)}>
            {parse(t("Topic3_Section3_instructions.here"))}
          </span>{" "}
          {parse(t("Topic3_Section3_instructions.ifyouneedhelp"))}
        </span>,
        "",
        "",
        "",
        "",
        "",
      ],

      successMsg: [
        parse(t("Topic3_Section4_msgs.successMsg[0]")),
        "",
        "",
        "",
        "",
        "",
        "",
      ],
      finalErrorMsg: [
        parse(t("Topic3_Section4_msgs.finalErrorMsg[0]")),
        parse(t("Topic3_Section4_msgs.finalErrorMsg[1]")),
        "",
        "",
        "",
        "",
        "",
      ],
    },
    table: {
      headers: [
        parse(t("Topic3_Section4_table.headers[0]")),
        parse(t("Topic3_Section4_table.headers[1]")),
        parse(t("Topic3_Section4_table.headers[2]")),
        parse(t("Topic3_Section4_table.headers[3]")),
        parse(t("Topic3_Section4_table.headers[4]")),
      ],
      data: [
        ["1", "1905", "10", "1.00", "2"],
        ["2", "1930", "613", "0.92", "85"],
        ["", "1935", "1189", "0.84", "151"],
        ["", "1940", "2139", "0.71", "230"],
        ["3", "1944", "3157", "-", "-"],
        ["", "1946", "3750", "-", "-"],
        ["", "1950", "4810", "-", "-"],
        ["4", "1996", "7500", "-", "-"],
      ],
    },
  }
  // ============================================================================================
  const [part, setPart] = useState(0)
  const [section, setSection] = useState(4)
  const [errorCount, setErrorCount] = useState(0)
  const [msgType, setMsgType] = useState("")
  const [msgContent, setMsgContent] = useState("")
  const [showMsg, setShowMsg] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false)
  const [answer, setAnswer] = useState(0)
  const [answer2, setAnswer2] = useState(0)
  const [currentYear, setCurrentYear] = useState(4)
  const [modalShow, setModalShow] = useState(false)
  const [showRadioCheck, setShowRadioCheck] = useState(true)
  const [showRadio2Check, setShowRadio2Check] = useState(true)
  const [disableChoices, setDisableChoices] = useState(false)
  const [resetPlaceholder, setResetPlaceholder] = useState(false)

  // ============================================================================================
  return (
    <Layout>
      <SEO title={parse(t("Topic3_Section4_pageTitle"))} />
      {/* <MyBreadcrumb topicTitle="Topic 1: Exponential Growth" topicLink="" /> */}
      <MyBreadcrumb
        topicLink="epopulationgrowth"
        topicTitle={parse(t("Topic3_Section4_breadcrumb[0]"))}
        currentTitle={pageContent.breadcrumb[part]}
      />

      <div className="homeContent">
        <Row>
          <Col sm="12">
            <TopHeader
              title={pageContent.title[part]}
              content={pageContent.topText[section]}
            />
          </Col>
          {/* <Col sm="4">
            <img
              src={pageContent.images[section]}
              className="roundMe"
              style={{ maxWidth: `290px`, marginBottom: `1.45rem` }}
            />
            <p className="smText">{pageContent.imageText[section]}</p>
          </Col> */}
        </Row>

        <div>
          <Row>
            <Col sm="12" md="12" hidden={part == 2}>
              <Instructions content={pageContent.instructions[part]} />
            </Col>
          </Row>
          <Row>
            <Col sm="6" hidden={part == 5}>
              <span>
                <Form hidden={part != 1}>
                  <p> {pageContent.labels[part]}</p>
                  <RadioQuestion
                    data={[
                      {
                        id: "radio1",
                        name: "formRadios",
                        label: pageContent.radios[0].label,
                        answer: pageContent.radios[0].answer,
                        checked: showRadioCheck == false ? 0 : null,
                      },
                      {
                        id: "radio2",
                        name: "formRadios",
                        label: pageContent.radios[1].label,
                        answer: pageContent.radios[1].answer,
                        checked: showRadioCheck == false ? 0 : null,
                      },
                      {
                        id: "radio3",
                        name: "formRadios",
                        label: pageContent.radios[2].label,
                        answer: pageContent.radios[2].answer,
                        checked: showRadioCheck == false ? 0 : null,
                      },
                    ]}
                    // disabled={disableChoices == true}
                    myOnClick={(answer, label, id) => {
                      switch (id) {
                        case "radio1":
                          setMsgContent(pageContent.radios[0].msg)
                          break
                        case "radio2":
                          setMsgContent(pageContent.radios[1].msg)
                          break
                        case "radio3":
                          setMsgContent(pageContent.radios[2].msg)
                          break
                        case "radio4":
                          setMsgContent(pageContent.radios[3].msg)
                          break
                        default:
                          return null
                      }
                      if (answer == true) {
                        setMsgType("correct")
                      } else {
                        setMsgType("error")
                      }
                      setShowMsg(true)
                      setDisableChoices(answer)
                      setNextBtnEnabled(answer)
                    }}
                    disabled={disableChoices}
                  />
                  <RespMsg
                    type={msgType}
                    content={msgContent}
                    hidden={!showMsg}
                  />
                </Form>

                <Form hidden={part != 20}>
                  <p> {pageContent.labels[part]}</p>
                  <RadioQuestion
                    data={[
                      {
                        id: "radio5",
                        name: "formRadios2",
                        label: pageContent.radios2[0].label,
                        answer: pageContent.radios2[0].answer,
                        checked: showRadio2Check == false ? 0 : null,
                      },
                      {
                        id: "radio6",
                        name: "formRadios2",
                        label: pageContent.radios2[1].label,
                        answer: pageContent.radios2[1].answer,
                        checked: showRadio2Check == false ? 0 : null,
                      },
                      {
                        id: "radio7",
                        name: "formRadios2",
                        label: pageContent.radios2[2].label,
                        answer: pageContent.radios2[2].answer,
                        checked: showRadio2Check == false ? 0 : null,
                      },
                    ]}
                    // disabled={disableChoices == true}
                    myOnClick={(answer, label, id) => {
                      switch (id) {
                        case "radio5":
                          setMsgContent(pageContent.radios2[0].msg)
                          break
                        case "radio6":
                          setMsgContent(pageContent.radios2[1].msg)
                          break
                        case "radio7":
                          setMsgContent(pageContent.radios2[2].msg)
                          break
                        default:
                          return null
                      }
                      if (answer == true) {
                        setMsgType("correct")
                      } else {
                        setMsgType("error")
                      }
                      setShowMsg(true)
                      setDisableChoices(answer)
                      setNextBtnEnabled(answer)
                    }}
                    disabled={disableChoices}
                  />
                  <RespMsg
                    type={msgType}
                    content={msgContent}
                    hidden={!showMsg}
                  />
                </Form>
              </span>
              {/* FIRST PART */}
              <div hidden={currentYear >= 7}>
                <label>{pageContent.labels[part][0]}</label>{" "}
                <Form.Control
                  placeholder="1-(N/K)"
                  disabled={errorCount >= 4}
                  onChange={e => setAnswer(e.target.value)}
                  type="number"
                  value={resetPlaceholder == true ? "" : null}
                  // minlength="9"
                  // maxlength="11"
                />
                <br />
                <label>{pageContent.labels[part][1]}</label>{" "}
                <Form.Control
                  placeholder="dN/dt"
                  disabled={errorCount >= 4}
                  onChange={e => setAnswer2(e.target.value)}
                  type="number"
                  value={resetPlaceholder == true ? "" : null}
                  // minlength="9"
                  // maxlength="11"
                />{" "}
                <br />
                <RespMsg
                  type={msgType}
                  content={msgContent}
                  hidden={!showMsg}
                />
                <ButtonLink
                  // btnLink="#top"
                  btnWidth
                  variant="info"
                  className="btnNext"
                  btnText={parse(t("Submit"))}
                  disabled={(errorCount >= 4) | nextBtnEnabled}
                  onClick={() => {
                    if (
                      (answer != pageContent.answers[currentYear][0]) |
                      (answer2 != pageContent.answers[currentYear][1])
                    ) {
                      setMsgContent(pageContent.msgs.errorMsg[0])
                      setMsgType("error")
                      setErrorCount(errorCount + 1)
                    } else {
                      setMsgContent(pageContent.msgs.successMsg[0])
                      setMsgType("correct")
                      setNextBtnEnabled(true)
                      setResetPlaceholder(true)
                    }
                    if (
                      errorCount >= 3 &&
                      (answer != pageContent.answers[section][0]) |
                        (answer2 != pageContent.answers[section][1])
                    ) {
                      setMsgContent(pageContent.msgs.finalErrorMsg[0])
                      setMsgType("error")
                      setNextBtnEnabled(true)
                      setResetPlaceholder(true)
                    }
                    setShowMsg(true)
                  }}
                  // btnColor="#4A331C"
                />
              </div>
            </Col>
            <Col sm="8" hidden={part != 2}>
              <br />
              {pageContent.bottomText}
            </Col>
            <Col sm="4" hidden={part != 2}>
              <img src={elephant} alt={parse(t("Topic3_Section4_imgAlt"))} />
            </Col>
            <Col sm="6" hidden={part == 2}>
              {" "}
              <table>
                <tr className="rightChilds">
                  <th>{pageContent.table.headers[0]}</th>
                  <th>{pageContent.table.headers[1]}</th>
                  <th>{pageContent.table.headers[2]}</th>
                  <th>{pageContent.table.headers[3]}</th>
                  <th>{pageContent.table.headers[4]}</th>
                </tr>
                <tr
                  className={
                    currentYear == 0 && part == 0
                      ? "activeYear rightChilds"
                      : "rightChilds"
                  }
                >
                  <td>{pageContent.table.data[0][0]}</td>
                  <td>{pageContent.table.data[0][1]}</td>
                  <td>{pageContent.table.data[0][2]}</td>
                  <td>
                    {(nextBtnEnabled == true && section == 0) | (part > 0)
                      ? pageContent.answers[0][0].toFixed(2)
                      : pageContent.table.data[0][3]}
                  </td>
                  <td>
                    {(nextBtnEnabled == true && section == 0) | (part > 0)
                      ? pageContent.answers[0][1]
                      : pageContent.table.data[0][4]}
                  </td>
                </tr>
                <tr
                  className={
                    currentYear == 1 && section == 1
                      ? "activeYear rightChilds"
                      : "rightChilds"
                  }
                >
                  <td>{pageContent.table.data[1][0]}</td>
                  <td>{pageContent.table.data[1][1]}</td>
                  <td>{pageContent.table.data[1][2]}</td>
                  <td>
                    {(nextBtnEnabled == true && section == 1) |
                    (currentYear > 1)
                      ? pageContent.answers[1][0].toFixed(2)
                      : pageContent.table.data[1][3]}
                  </td>
                  <td>
                    {(nextBtnEnabled == true && section == 1) |
                    (currentYear > 1)
                      ? pageContent.answers[1][1]
                      : pageContent.table.data[1][4]}
                  </td>
                </tr>
                <tr
                  className={
                    currentYear == 2 && section == 1
                      ? "activeYear rightChilds"
                      : "rightChilds"
                  }
                >
                  <td>{pageContent.table.data[2][0]}</td>
                  <td>{pageContent.table.data[2][1]}</td>
                  <td>{pageContent.table.data[2][2]}</td>
                  <td>
                    {(nextBtnEnabled == true && currentYear == 2) |
                    (currentYear > 2)
                      ? pageContent.answers[2][0].toFixed(2)
                      : pageContent.table.data[2][3]}
                  </td>
                  <td>
                    {(nextBtnEnabled == true && currentYear == 2) |
                    (currentYear > 2)
                      ? pageContent.answers[2][1]
                      : pageContent.table.data[2][4]}
                  </td>
                </tr>
                <tr
                  className={
                    currentYear == 3 && section == 1
                      ? "activeYear rightChilds"
                      : "rightChilds"
                  }
                >
                  <td>{pageContent.table.data[3][0]}</td>
                  <td>{pageContent.table.data[3][1]}</td>
                  <td>{pageContent.table.data[3][2]}</td>
                  <td>
                    {(nextBtnEnabled == true && currentYear == 3) |
                    (currentYear > 3)
                      ? pageContent.answers[3][0].toFixed(2)
                      : pageContent.table.data[3][3]}
                  </td>
                  <td>
                    {(nextBtnEnabled == true && currentYear == 3) |
                    (currentYear > 3)
                      ? pageContent.answers[3][1]
                      : pageContent.table.data[3][4]}
                  </td>
                </tr>
                {/* SECTION 4 */}
                <tr
                  className={
                    currentYear == 4 && section == 4
                      ? "activeYear rightChilds"
                      : "rightChilds"
                  }
                >
                  <td>{pageContent.table.data[4][0]}</td>
                  <td>{pageContent.table.data[4][1]}</td>
                  <td>{pageContent.table.data[4][2]}</td>
                  <td>
                    {(nextBtnEnabled == true && section == 4) |
                    (currentYear > 4)
                      ? pageContent.answers[4][0].toFixed(2)
                      : pageContent.table.data[4][3]}
                  </td>
                  <td>
                    {(nextBtnEnabled == true && section == 4) |
                    (currentYear > 4)
                      ? pageContent.answers[4][1]
                      : pageContent.table.data[4][4]}
                  </td>
                </tr>
                <tr
                  className={
                    currentYear == 5 && section == 4
                      ? "activeYear rightChilds"
                      : "rightChilds"
                  }
                >
                  <td>{pageContent.table.data[5][0]}</td>
                  <td>{pageContent.table.data[5][1]}</td>
                  <td>{pageContent.table.data[5][2]}</td>
                  <td>
                    {(nextBtnEnabled == true && currentYear == 5) |
                    (currentYear > 5)
                      ? pageContent.answers[5][0].toFixed(2)
                      : pageContent.table.data[5][3]}
                  </td>
                  <td>
                    {(nextBtnEnabled == true && currentYear == 5) |
                    (currentYear > 5)
                      ? pageContent.answers[5][1]
                      : pageContent.table.data[5][4]}
                  </td>
                </tr>
                <tr
                  className={
                    currentYear == 6 && section == 4
                      ? "activeYear rightChilds"
                      : "rightChilds"
                  }
                >
                  <td>{pageContent.table.data[6][0]}</td>
                  <td>{pageContent.table.data[6][1]}</td>
                  <td>{pageContent.table.data[6][2]}</td>
                  <td>
                    {(nextBtnEnabled == true && currentYear == 6) |
                    (currentYear > 6)
                      ? pageContent.answers[6][0].toFixed(2)
                      : pageContent.table.data[6][3]}
                  </td>
                  <td>
                    {(nextBtnEnabled == true && currentYear == 6) |
                    (currentYear > 6)
                      ? pageContent.answers[6][1]
                      : pageContent.table.data[6][4]}
                  </td>
                </tr>
                <tr
                  className={
                    currentYear == 7 && section == 0
                      ? "activeYear rightChilds"
                      : "rightChilds"
                  }
                >
                  <td>{pageContent.table.data[7][0]}</td>
                  <td>{pageContent.table.data[7][1]}</td>
                  <td>{pageContent.table.data[7][2]}</td>
                  <td>
                    {(nextBtnEnabled == true && section == 7) | (part > 7)
                      ? pageContent.answers[7][0].toFixed(2)
                      : pageContent.table.data[7][3]}
                  </td>
                  <td>
                    {(nextBtnEnabled == true && section == 7) | (part > 7)
                      ? pageContent.answers[7][1]
                      : pageContent.table.data[7][4]}
                  </td>
                </tr>
              </table>
            </Col>
          </Row>
          <br />
        </div>
        <hr />

        <MyModal
          size="lg"
          className="rModal"
          show={modalShow}
          onHide={() => setModalShow(false)}
          centered
        />

        <Row>
          <Col sm="12" md="12" className="alignRight">
            <BottomNav
              prevName={parse(t("Previous"))}
              btnLinkPrev={
                part == 0 ? "/epopulationgrowth/section3" : "#myHeader"
              }
              onClickPrev={() => {
                setMsgType("")
                setMsgContent("")
                setShowMsg(false)
                setErrorCount(0)
                switch (part) {
                  case 1:
                    setNextBtnEnabled(false)
                    setPart(part - 1)
                  case 2:
                    setNextBtnEnabled(false)

                    setPart(part - 1)
                  default:
                    return null
                }
              }}
              btnLinkNext={
                part == 2 ? "/epopulationgrowth/section5" : "#myHeader"
              }
              nextName={parse(t("Next"))}
              onClickNext={() => {
                setMsgType("")
                setMsgContent("")
                setShowMsg(false)
                setResetPlaceholder(false)
                setErrorCount(0)
                // setCurrentYear(currentYear + 1)
                if (part == 0) {
                  setCurrentYear(currentYear + 1)
                  setNextBtnEnabled(false)
                }
                if (currentYear == 6 && part == 0) {
                  setPart(part + 1)
                  setDisableChoices(false)
                  setNextBtnEnabled(false)
                }
                if (currentYear == 7 && part == 1) {
                  setPart(part + 1)
                  setDisableChoices(false)
                  setNextBtnEnabled(true)
                }
              }}
              disabled={!nextBtnEnabled}
            />
          </Col>
        </Row>
      </div>
      <br />
    </Layout>
  )
}

export default EPopGrowthSection4
